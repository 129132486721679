<!-- 余额提现 -->
<template>
    <!-- 内容 -->
    <div class="financebox">
        <div class="ordermanageboxup">
            <ul class="d-flex flex-wrap">
                <li class="clearfloat">
                    <label class="orderlabel">会员信息</label>
                    <div class="orderfirstinput reportinp vipxinxi">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="手机号/微信昵称/姓名">
                    </div>
                </li>
                <li>
                    <div class="orderfirst orderfirsted clearfloat">
                        <label class="orderlabel">申请时间</label>
                        <div class="orderfirstinput orderbatchtime">
                            <el-date-picker v-model="valuetimes" type="datetimerange"
                                :picker-options="{ 'firstDayOfWeek': 1 }" start-placeholder="开始日期" format="yyyy-MM-dd"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    </div>
                </li>
                <li class="clearfloat">
                    <label class="orderlabel">提现方式</label>
                    <div class="orderselect">
                        <template>
                            <el-select v-model="withrawtype" clearable placeholder="请选择">
                                <el-option value="全部">全部</el-option>
                                <el-option value="门店一">门店一</el-option>
                            </el-select>
                        </template>
                    </div>
                </li>
                <li class="clearfloat">
                    <label class="orderlabel">会员等级</label>
                    <div class="orderselect">
                        <template>
                            <el-select v-model="vipvalue" clearable placeholder="请选择">
                                <el-option value="全部">全部</el-option>
                                <el-option value="默认会员">默认会员</el-option>
                                <el-option value="金卡">金卡</el-option>
                                <el-option value="银卡">银卡</el-option>
                            </el-select>
                        </template>
                    </div>
                </li>

                <li class="clearfloat">
                    <button class="ordersearch">重置条件</button>
                </li>
            </ul>
        </div>
        <div class="withdrawbut">
            <div class="batchtable">
                <div class="ordermanagenav">
                    <div class="ordermanagenavleft d-flex">
                        <ul class="clearfloat">
                            <li class="active"><a href="javascript:void(0);">全部</a></li>
                            <li><a href="javascript:void(0);">待审核</a></li>
                            <li><a href="javascript:void(0);">已通过</a></li>
                            <li><a href="javascript:void(0);">未通过</a></li>
                        </ul>
                    </div>
                </div>
                <div class="batchtablecon">
                    <table>
                        <thead>
                            <tr>
                                <th class="tableleft">用户信息</th>
                                <th>手机号</th>
                                <th>提现方式</th>
                                <th>提现金额</th>
                                <th>时间</th>
                                <th>状态</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <colgroup>
                            <col style="width:18%">
                            <col style="width:13%">
                            <col style="width:12%">
                            <col style="width:12%">
                            <col style="width:17%">
                            <col style="width:14%">
                            <col style="width:14%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="~@/assets/images/cs1.png" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 176</p>
                                        </div>
                                    </div>
                                </td>
                                <td>15232885599</td>
                                <td>微信</td>
                                <td>125845.00</td>
                                <td>2023.01.01 00:00:00</td>
                                <td>
                                    <div class="financezt">
                                        <span class="finsuccess"><i class="el-icon-success"></i> 已转账</span>
                                        <span class="finwzf" style="display: none;"><i class="el-icon-warning"></i>
                                            审核中</span>
                                        <span class="finytk" style="display: none;"><i class="el-icon-error"></i> 已拒绝</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);">同意</a>
                                        <a href="javascript:void(0);" @click="refusetype = true">拒绝</a>
                                        <a href="javascript:void(0);" @click="operatortype = true">查看备注</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="~@/assets/images/cs1.png" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 22</p>
                                        </div>
                                    </div>
                                </td>
                                <td>13700041300</td>
                                <td>微信</td>
                                <td>5845.00</td>
                                <td>2021.11.01 12:00:00</td>
                                <td>
                                    <div class="financezt">
                                        <span class="finsuccess" style="display: none;"><i class="el-icon-success"></i>
                                            已转账</span>
                                        <span class="finwzf" style="display: none;"><i class="el-icon-warning"></i>
                                            审核中</span>
                                        <span class="finytk"><i class="el-icon-error"></i> 已拒绝</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);">同意</a>
                                        <a href="javascript:void(0);" @click="refusetype = true">拒绝</a>
                                        <a href="javascript:void(0);" @click="operatortype = true">查看备注</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="tableleft">
                                    <div class="memberuser clearfloat">
                                        <div class="memberuserleft">
                                            <img src="~@/assets/images/cs1.png" alt="">
                                        </div>
                                        <div class="memberuseright">
                                            <p>钟~Gavinจุ๊บ</p>
                                            <p>ID: 68</p>
                                        </div>
                                    </div>
                                </td>
                                <td>17600454544</td>
                                <td>微信</td>
                                <td>45.00</td>
                                <td>2013.03.01 03:00:00</td>
                                <td>
                                    <div class="financezt">
                                        <span class="finsuccess" style="display: none;"><i class="el-icon-success"></i>
                                            已转账</span>
                                        <span class="finwzf"><i class="el-icon-warning"></i> 审核中</span>
                                        <span class="finytk" style="display: none;"><i class="el-icon-error"></i> 已拒绝</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);">同意</a>
                                        <a href="javascript:void(0);" @click="refusetype = true">拒绝</a>
                                        <a href="javascript:void(0);" @click="operatortype = true">查看备注</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tablepage clearfloat">
                    <div class="orderlastbutright">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage1" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                            layout="total, sizes, prev, pager, next, jumper" :total="400">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 查看备注弹层 -->
        <el-dialog title="备注" class="operatortc" :visible.sync="operatortype">
            <div class="operatorbox">
                <p>转账方式：<span>转账到银行</span></p>
                <p>银行卡号：<span>2849595969</span></p>
                <p>开户人：<span>哈哈</span></p>
                <p>开户行：<span>哈哈</span></p>
                <p>实际转账方式：<span>转账到银行</span></p>
                <p>备注：<span>备注说明备注说明备注说明备注说明备注说明备注说明</span></p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="operatortype = false">取 消</el-button>
                <el-button @click="operatortype = false" class="addressqr">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 拒绝打款弹层 -->
        <el-dialog title="拒绝" class="refusetc" :visible.sync="refusetype">
            <div class="refusebox">
                <el-input type="textarea" v-model.trim="textareaValue" maxlength="255" show-word-limit resize="none"
                    class="textarea-box" placeholder="拒绝理由(最多输入255字)"></el-input>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="refusetype = false">取 消</el-button>
                <el-button @click="refusetype = false" class="addressqr">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentPage1: 1,
            valuetimes: '',//充值时间
            withrawtype: '',//提现方式
            vipvalue: '',//会员等级
            operatortype: false,//控制备注弹层是否显示
            refusetype: false,//控制拒绝打款是否显示
            textareaValue:"",
        }
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        }
    }
}
</script>

<style scoped>
@import url("css/finance.css");

</style>